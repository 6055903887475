// This file can be replaced during build by using the `fileReplacements` array.
export const environment = {
        production: false,
        stripe_token: 'STRIPE_TOKEN',
        paypal_token: 'PAYPAL_TOKEN',
        loadGif: 'assets/images/loader.gif',

        // IANOS
        /*firebase: {
            apiKey: "AIzaSyDPw9J-pflR8khJ4PeFp-owHu-gUveYZLs",
            authDomain: "ianos-platform-dev.firebaseapp.com",
            databaseURL: "https://ianos-platform-dev-default-rtdb.firebaseio.com",
            projectId: "ianos-platform-dev",
            storageBucket: "ianos-platform-dev.appspot.com",
            messagingSenderId: "453907306516",
            appId: "1:453907306516:web:35d0a4df6afece46d82f01",
            measurementId: "G-1TY39ZT2WS"
        },
      */
        loading: {
            spinner: 'circles',
            duration: 3000
        },
        /*
           /!* googleMapsKey: 'AIzaSyCnyj164hLm7drlr85gbQ8tAIQD_ci8KxE',
            urlBase: 'https://us-central1-ianos-platform-dev.cloudfunctions.net',
            urlBaseWompy: 'https://us-central1-ianos-platform-dev.cloudfunctions.net/Wompi',
            apikey: '6a4747e52f7-0428b4969d52d-3fb2-f509dd16',*!/

            // new rolling
            // googleMapsKey: 'AIzaSyCnyj164hLm7drlr85gbQ8tAIQD_ci8KxE',
            // urlBase: 'https://us-central1-newrolling-pro.cloudfunctions.net',
            // urlBaseWompy: 'https://us-central1-newrolling-pro.cloudfunctions.net/Wompi',
            // urlBaseNR: 'https://api.newrolling.com',
            // apikey: '97ccc90e8fe-cce525a10ddf3-4cf7-415a29cd',

            // CREDENCIALES DE PRO
            // wompi: {
            //   grant_type: 'client_credentials', // Dejar siempre client_credentials.
            //   audience: 'wompi_api', // Dejar siempre "wompi_api"
            // tslint:disable-next-line:max-line-length
            //   client_id: 'aa4c30e1-0757-4d03-a20f-b9cc2663bf5c', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
            //   client_secret: '259c97c1-06fe-4137-ab29-b331b92f0fcb', //Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
            // },


            // TIENDA CH
            /!*urlBase: 'https://us-central1-ch-tienda-online-app-dev.cloudfunctions.net',
            apikey: 'c159bde8e57-6c1149f12ee44-418c-eee56df2',
            urlBaseWompy: 'https://us-central1-ch-tienda-online-app-dev.cloudfunctions.net/Wompi',
            firebase: {
                apiKey: 'AIzaSyA6Ri30hxwSGkopJkPXDpirF2cyiorslaU',
                authDomain: 'ch-tienda-online-app-dev.firebaseapp.com',
                projectId: 'ch-tienda-online-app-dev',
                storageBucket: 'ch-tienda-online-app-dev.appspot.com',
                messagingSenderId: '543665834717',
                appId: '1:543665834717:web:d72a45b2950328f9236934',
                measurementId: 'G-E9NVECM858'
            },
            loadGif: 'assets/images/loader.gif',*!/
            // loadGif: 'https://firebasestorage.googleapis.com/v0/b/ch-tienda-online-app-dev.appspot.com/o/assets%2Fimages%2Fgif%2Fload_ch.gif?alt=media&token=86249d71-6b77-4113-b20b-d2cedfbde5f8',


            // NEW ROLLING DEV
            urlBase: 'https://us-central1-ianos-newrolling-dev.cloudfunctions.net',
            apikey: 'c68790b2244-50a832abf6f6e-4f4c-606dc078',
            urlBaseWompy: 'https://us-central1-ianos-platform-dev.cloudfunctions.net/Wompi',
            firebase: {
                apiKey: "AIzaSyACt8BEF8zwh49ErWHvj14ZdKwL-mqlLsE",
                authDomain: "ianos-newrolling-dev.firebaseapp.com",
                projectId: "ianos-newrolling-dev",
                storageBucket: "ianos-newrolling-dev.appspot.com",
                messagingSenderId: "1059308592105",
                appId: "1:1059308592105:web:0aba5eef8bf6f7cd100978",
                measurementId: "G-M90M91QVE7"
            },
            loadGif: 'assets/images/loader.gif',
            bannerDefault: 'https://firebasestorage.googleapis.com/v0/b/newrolling-pro.appspot.com/o/assets%2Fimages%2Fbanner-default.jpg?alt=media&token=370f7870-1d05-4259-8a22-064e33789d08',
            // loadGif: 'https://firebasestorage.googleapis.com/v0/b/newrolling-pro.appspot.com/o/assets%2Fimages%2Fnew-rolling-gif-animated.gif?alt=media&token=7f2878cb-9302-4ac7-8a48-0469ee9ad92d',
            */


        // -------------------------------------------------------------------------------------------------------------------------------------

        // Ianos dev
        // firebase: {
        //     projectId: 'ianos-platform-dev',
        //     appId: '1:453907306516:web:35d0a4df6afece46d82f01',
        //     databaseURL: 'https://ianos-platform-dev-default-rtdb.firebaseio.com',
        //     storageBucket: 'ianos-platform-dev.appspot.com',
        //     locationId: 'us-central',
        //     apiKey: 'AIzaSyDPw9J-pflR8khJ4PeFp-owHu-gUveYZLs',
        //     authDomain: 'ianos-platform-dev.firebaseapp.com',
        //     messagingSenderId: '453907306516',
        //     measurementId: 'G-1TY39ZT2WS',
        // },
        // googleMapsKey: 'AIzaSyCnyj164hLm7drlr85gbQ8tAIQD_ci8KxE',
        // urlBase: 'https://us-central1-ianos-platform-dev.cloudfunctions.net',
        // apikey: '6a4747e52f7-0428b4969d52d-3fb2-f509dd16',
        // urlBaseWompy: 'https://us-central1-ianos-platform-dev.cloudfunctions.net/Wompi',
        // bannerDefault: 'https://firebasestorage.googleapis.com/v0/b/ianos-platform-dev.appspot.com/o/assets%2Fimages%2Fstore%2Fianos-banner-default.png?alt=media&token=d5195338-f2cb-4408-9137-2695c3b734a4',

        // Ianos UAT
        // firebase: {
        //     apiKey: 'AIzaSyA--I9LZbUEkPlzMxjXXnSgEVsTxs3eKzs',
        //     authDomain: 'ianos-app-platform-uat.firebaseapp.com',
        //     projectId: 'ianos-app-platform-uat',
        //     storageBucket: 'ianos-app-platform-uat.appspot.com',
        //     messagingSenderId: '464608022096',
        //     appId: '1:464608022096:web:5f49f4e4e779fe9ef84b55',
        //     measurementId: 'G-4T268Q7389'
        // },
        // googleMapsKey: 'AIzaSyCnyj164hLm7drlr85gbQ8tAIQD_ci8KxE',
        // urlBase: 'https://us-central1-ianos-app-platform-uat.cloudfunctions.net',
        // apikey: 'f7aa1edfa9f-52ef629ef8f50-40ab-42faf4fe',
        // urlBaseWompy: 'https://us-central1-ianos-app-platform-uat.cloudfunctions.net/Wompi',
        // bannerDefault: 'https://firebasestorage.googleapis.com/v0/b/ianos-platform-dev.appspot.com/o/assets%2Fimages%2Fstore%2Fianos-banner-default.png?alt=media&token=d5195338-f2cb-4408-9137-2695c3b734a4',

        // PARAMETROS NEW ROLLING PRO
        // firebase: {
        //     apiKey: 'AIzaSyBgmFSjwQsnXaWREGx6wKIJ23c5_qhCeqI',
        //     authDomain: 'newrolling-pro.firebaseapp.com',
        //     projectId: 'newrolling-pro',
        //     storageBucket: 'newrolling-pro.appspot.com',
        //     messagingSenderId: '883404852630',
        //     appId: '1:883404852630:web:c9496b6151d0689e0861b7',
        //     measurementId: 'G-8E9QPRPXFE'
        // },
        // bannerDefault: 'https://firebasestorage.googleapis.com/v0/b/newrolling-pro.appspot.com/o/assets%2Fimages%2Fbanner-default.jpg?alt=media&token=370f7870-1d05-4259-8a22-064e33789d08',
        // urlBase: 'https://us-central1-newrolling-pro.cloudfunctions.net',
        // urlBaseWompy: 'https://us-central1-newrolling-pro.cloudfunctions.net/Wompi',
        // urlBaseNR: 'https://api.newrolling.com',
        // apikey: '97ccc90e8fe-cce525a10ddf3-4cf7-415a29cd',


        // PARAMETROS NEW ROLLING UAT
        // firebase: {
        //     apiKey: 'AIzaSyCVXxjtAV0nvITwqwHWkDH2el3SthFjP44',
        //     authDomain: 'ianos-app-new-rolling-uat.firebaseapp.com',
        //     projectId: 'ianos-app-new-rolling-uat',
        //     storageBucket: 'ianos-app-new-rolling-uat.appspot.com',
        //     messagingSenderId: '221714662002',
        //     appId: '1:221714662002:web:3b8e710323a3a167fdb8eb',
        //     measurementId: 'G-C579LV9TT0'
        // },
        // urlBase: 'https://us-central1-ianos-app-new-rolling-uat.cloudfunctions.net',
        // urlBaseWompy: 'https://us-central1-ianos-app-new-rolling-uat.cloudfunctions.net/Wompi',
        // urlBaseNR: 'https://api.newrolling.com',
        // apikey: '5da27d6dcec-5994e985f31fa-4242-59caf805',
        // bannerDefault: 'https://firebasestorage.googleapis.com/v0/b/ianos-app-new-rolling-uat.appspot.com/o/assets%2Fimages%2Fstore%2Fianos-new-rolling-banner-default.jpeg?alt=media&token=2096bc90-9065-43f3-9f3c-6af791327a97',


        // PARAMETROS NEW ROLLING DEV
        // firebase: {
        //     apiKey: "AIzaSyACt8BEF8zwh49ErWHvj14ZdKwL-mqlLsE",
        //     authDomain: "ianos-newrolling-dev.firebaseapp.com",
        //     projectId: "ianos-newrolling-dev",
        //     storageBucket: "ianos-newrolling-dev.appspot.com",
        //     messagingSenderId: "1059308592105",
        //     appId: "1:1059308592105:web:0aba5eef8bf6f7cd100978",
        //     measurementId: "G-M90M91QVE7"
        // },
        // loadGif: 'assets/images/loader.gif',
        // bannerDefault: 'https://firebasestorage.googleapis.com/v0/b/newrolling-pro.appspot.com/o/assets%2Fimages%2Fbanner-default.jpg?alt=media&token=370f7870-1d05-4259-8a22-064e33789d08',
        //
        // urlBase: 'https://us-central1-ianos-newrolling-dev.cloudfunctions.net',
        // urlBaseWompy: 'https://us-central1-ianos-newrolling-dev.cloudfunctions.net/Wompi',
        // urlBaseNR: 'https://api.newrolling.com',
        // apikey: 'c68790b2244-50a832abf6f6e-4f4c-606dc078',
        //
        // googleMapsKey: 'AIzaSyCDk6GYGJWDeVPbgqlfOKGSXZ-9-eXBpW4',

        // PARAMETROS eBizguides
        // firebase: {
        //     apiKey: 'AIzaSyCkkXKTvR-RdO3sDABPZ2Qn8IQXL-7IReM',
        //     authDomain: 'ianos-fr-ebizguides-pro.firebaseapp.com',
        //     projectId: 'ianos-fr-ebizguides-pro',
        //     storageBucket: 'ianos-fr-ebizguides-pro.appspot.com',
        //     messagingSenderId: '1035324522218',
        //     appId: '1:1035324522218:web:0866da13182b59e1956193',
        //     measurementId: 'G-VZ44YH0BDJ'
        // },
        // urlBase: 'https://us-central1-ianos-fr-ebizguides-pro.cloudfunctions.net',
        // urlBaseWompy: 'https://us-central1-ianos-fr-ebizguides-pro.cloudfunctions.net/Wompi',
        // apikey: 'ddafbd65c4b-77c116ad06762-4a93-d20669f6',
        // bannerDefault: 'https://firebasestorage.googleapis.com/v0/b/ianos-fr-ebizguides-pro.appspot.com/o/assets%2Fimages%2Fstore%2Fianos-banner-default-_ebizguide-1900x600px.jpg?alt=media&token=d0fa2c24-91b2-4ac7-90ae-85a206ef8fb6',

        // PARAMETROS Tactical Store
        // firebase: {
        //     apiKey: 'AIzaSyCXHMBA-w4dHsTgCWA9XVmn6br_MTu-b0Y',
        //     authDomain: 'ianosapp-sv-fntacticalstorepro.firebaseapp.com',
        //     projectId: 'ianosapp-sv-fntacticalstorepro',
        //     storageBucket: 'ianosapp-sv-fntacticalstorepro.appspot.com',
        //     messagingSenderId: '1070062927608',
        //     appId: '1:1070062927608:web:5a1845e7831e64078c850d',
        //     measurementId: 'G-QL59SPELV8'
        // },
        // urlBaseWompy: 'https://us-central1-ianosapp-sv-fntacticalstorepro.cloudfunctions.net/Wompi',
        // bannerDefault: 'https://firebasestorage.googleapis.com/v0/b/ianos-platform-dev.appspot.com/o/assets%2Fimages%2Fstore%2Fianos-banner-default.png?alt=media&token=d5195338-f2cb-4408-9137-2695c3b734a4',
        // urlBase: 'https://us-central1-ianosapp-sv-fntacticalstorepro.cloudfunctions.net',
        // apikey: '4602c13ebef-77f16c955f96e-4150-5192df39',

        // PARAMETROS San Martin
        // firebase: {
        //     apiKey: "AIzaSyAOMsJCjtGR_JD3GItRjF61hOKRvVhgAP4",
        //     authDomain: "ianos-app-gt-san-martin.firebaseapp.com",
        //     projectId: "ianos-app-gt-san-martin",
        //     storageBucket: "ianos-app-gt-san-martin.appspot.com",
        //     messagingSenderId: "713678723007",
        //     appId: "1:713678723007:web:d91ff9fed405052892730a",
        //     measurementId: "G-GDE7MWHTEG"
        // },
        // urlBaseWompy: 'https://us-central1-ianos-app-gt-san-martin.cloudfunctions.net/Wompi',
        // bannerDefault: 'https://firebasestorage.googleapis.com/v0/b/ianos-app-gt-san-martin.appspot.com/o/assets%2Fimages%2Fstore%2Fianos-banner-default-_sanmartin-1900x600px.png?alt=media&token=df16c490-cbd9-4714-a2b8-9e80e6fa55a4',
        // urlBase: 'https://us-central1-ianos-app-gt-san-martin.cloudfunctions.net',
        // apikey: 'cd0ec4d7b79-3c21d1ae26c5b-4f93-a2efcf6c',

        // PARAMETROS Mr Hot Dog dev
        // firebase: {
        //     apiKey: "AIzaSyA9W3398H2Bsw-E62Ww-PYI5-d4edOTKUo",
        //     authDomain: "ianos-app-mr-hot-dog-sv-dev.firebaseapp.com",
        //     projectId: "ianos-app-mr-hot-dog-sv-dev",
        //     storageBucket: "ianos-app-mr-hot-dog-sv-dev.appspot.com",
        //     messagingSenderId: "1084984684691",
        //     appId: "1:1084984684691:web:cfe4caa9c3dd956d531d66",
        //     measurementId: "G-52YJEY4BS3"
        // },
        // urlBaseWompy: 'https://us-central1-ianos-app-mr-hot-dog-sv-dev.cloudfunctions.net/Wompi',
        // bannerDefault: 'https://firebasestorage.googleapis.com/v0/b/ianos-app-mr-hot-dog-sv-dev.appspot.com/o/assets%2Fimages%2Fstore%2Fianos-banner-default-_mrhotdog-1900x600px.png?alt=media&token=81ba9c67-43f5-4454-9686-ef7037e09900',
        // urlBase: 'https://us-central1-ianos-app-mr-hot-dog-sv-dev.cloudfunctions.net',
        // apikey: 'e9c70110c08-ecad5b389a480-4ed3-9c138c9d',

        // PARAMETROS Greek Boys Choice Foods PRO
        firebase: {
            apiKey: "AIzaSyD4xkaZZklS8o7sNGH6tLU0OazWfBz2Q0A",
            authDomain: "ianos-app-us-greekboys-pro.firebaseapp.com",
            projectId: "ianos-app-us-greekboys-pro",
            storageBucket: "ianos-app-us-greekboys-pro.appspot.com",
            messagingSenderId: "363029428923",
            appId: "1:363029428923:web:766b7e7d8849824d63479e",
            measurementId: "G-0H2KQV81YB"
        },

        urlBase: 'https://us-central1-ianos-app-us-greekboys-pro.cloudfunctions.net',
        urlBaseWompy: 'https://us-central1-ianos-app-us-greekboys-pro.cloudfunctions.net/Wompi',
        apikey: '6dd5f108e1c-b704df14db02b-4171-5d6e24ef',
        googleMapsKey: 'AIzaSyCDk6GYGJWDeVPbgqlfOKGSXZ-9-eXBpW4',
        bannerDefault: 'https://firebasestorage.googleapis.com/v0/b/ianos-app-us-greekboys-pro.appspot.com/o/assets%2Fimages%2Fstore%2Fianos-banner-default-bg-Greek%20Boys_greek%20Boys.png?alt=media&token=507a0752-90de-4be1-b3d9-988a1d67988b',

        // PARAMETROS La veneciana
        // firebase: {
        //     apiKey: 'AIzaSyB7qqrv6Fvsu-Qod0Xal7ARCbvV_i9A1HE',
        //     authDomain: 'ianos-app-mx-la-veneciana-pro.firebaseapp.com',
        //     projectId: 'ianos-app-mx-la-veneciana-pro',
        //     storageBucket: 'ianos-app-mx-la-veneciana-pro.appspot.com',
        //     messagingSenderId: '723971402098',
        //     appId: '1:723971402098:web:23ad10987cb887e17755eb',
        //     measurementId: 'G-VGBSLPLYCR'
        // },
        // urlBaseWompy: 'https://us-central1-ianos-app-mx-la-veneciana-pro.cloudfunctions.net/Wompi',
        // bannerDefault: 'https://firebasestorage.googleapis.com/v0/b/ianos-app-mx-la-veneciana-pro.appspot.com/o/assets%2Fimages%2Fstore%2Fianos-banner-default-_laveneciana-1900x600px.png?alt=media&token=eb480dc5-103f-4406-bba1-2a49ddbc1a72',
        // urlBase: 'https://us-central1-ianos-app-mx-la-veneciana-pro.cloudfunctions.net',
        // apikey: 'c50be09df5f-e1b028172aa40-4ae8-a2e75ea1',

        // Pollo Real | PRO
        // firebase: {
        //     apiKey: 'AIzaSyD-3nHztWa1m8qB8narom0G_0B16eAoags',
        //     authDomain: 'ianos-app-sv-pollo-real-pro.firebaseapp.com',
        //     projectId: 'ianos-app-sv-pollo-real-pro',
        //     storageBucket: 'ianos-app-sv-pollo-real-pro.appspot.com',
        //     messagingSenderId: '820710339461',
        //     appId: '1:820710339461:web:e237b973381b183f4bcaec',
        //     measurementId: 'G-EZLGHR2LQ9'
        // },
        // bannerDefault: 'https://firebasestorage.googleapis.com/v0/b/ianos-app-sv-pollo-real-pro.appspot.com/o/assets%2Fimages%2Fbackground%2Fianos-banner-default-_pollo%20real-1900x600px.jpg?alt=media&token=b5170bf3-e891-4429-b94e-4bd3e6700be2&_gl=1*tnj5oc*_ga*MzI1OTI1NjQ0LjE2Nzk5MjYzODQ.*_ga_CW55HF8NVT*MTY4NjA4NzAwMy40OS4xLjE2ODYwOTA5MjAuMC4wLjA.',
        // urlBase: 'https://us-central1-ianos-app-sv-pollo-real-pro.cloudfunctions.net',
        // urlBaseWompy: 'https://us-central1-ianos-app-sv-pollo-real-pro.cloudfunctions.net/Wompi',
        // apikey: 'd7793bd7128-7cdb17b10d866-4726-eac008c1',
        // googleMapsKey: 'AIzaSyCDk6GYGJWDeVPbgqlfOKGSXZ-9-eXBpW4',

        // CREDENCIALES DE PRO New Rolling
        // wompi: {
        //     grant_type: 'client_credentials', // Dejar siempre client_credentials.
        //     audience: 'wompi_api', // Dejar siempre "wompi_api"
        //     client_id: 'aa4c30e1-0757-4d03-a20f-b9cc2663bf5c', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        //     client_secret: '259c97c1-06fe-4137-ab29-b331b92f0fcb', // Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        // },

        // CREDENCIALES DE PRO ITWC
        wompi: {
            grant_type: 'client_credentials', // Dejar siempre client_credentials.
            audience: 'wompi_api', // Dejar siempre "wompi_api"
            client_id: 'dc4c16b2-08d9-4478-9037-40a580004f6b', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
            client_secret: '2c1c79a5-8315-4989-8ade-1469183c0810', //Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        },

        // CREDENCIALES DE PRO Tactical Storage
        // wompi: {
        //     grant_type: 'client_credentials', // Dejar siempre client_credentials.
        //     audience: 'wompi_api', // Dejar siempre "wompi_api"
        //     client_id: '53f8abb1-b518-40d6-96ab-78c4e0a15324', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        //     client_secret: 'b5e4cd46-358a-4b87-bd22-dcb5e61897a1', //Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        // },

        // CREDENCIALES DE TEST New Rolling
        // wompi: {
        //     grant_type: 'client_credentials', // Dejar siempre client_credentials.
        //     audience: 'wompi_api', // Dejar siempre "wompi_api"
        //     client_id: 'e0c71a50-c0f7-42c3-89e4-fee51345fb96', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        //     client_secret: 'a5a9d301-6ca7-407d-94f0-ea6b868ec857', //Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        // },


        // TWILIO CREDENCIALES new rolling
        // twilio: {
        //     TWILIO_SERVICE_ENDPOINT: 'https://verify.twilio.com/v2/Services/VA037cd4a1d0556aceea8d4d9524af6c24/Verifications',
        //     TWILIO_SERVICE_VERIFICATION_CHECK: `https://verify.twilio.com/v2/Services/VA037cd4a1d0556aceea8d4d9524af6c24/VerificationCheck`,
        //     TWILIO_AUTH_TOKEN: 'eae5d7503587447a21af4311385d376c',
        //     TWILIO_ACCOUNT_SID: 'ACe4c513b1b576a81a7304c06951362f8c',
        // },

        // TWILIO CREDENCIALES IanOS Dev
        // twilio: {
        //     TWILIO_SERVICE_ENDPOINT : 'https://verify.twilio.com/v2/Services/VA6f37e2f3e25ae98433a02926ec75c77a/Verifications',
        //     TWILIO_SERVICE_VERIFICATION_CHECK : `https://verify.twilio.com/v2/Services/VA6f37e2f3e25ae98433a02926ec75c77a/VerificationCheck`,
        //     TWILIO_AUTH_TOKEN : '13b6af0768f7255a066f3436df02f6ca',
        //     TWILIO_ACCOUNT_SID : 'ACb5f6530b25d1d3d182d2df34d1747117',
        // },

        // TWILIO CREDENCIALES IanOS APP
        twilio: {
            TWILIO_SERVICE_ENDPOINT: 'https://verify.twilio.com/v2/Services/VA5681a04e24d8bc289d6008f39af37744/Verifications',
            TWILIO_SERVICE_VERIFICATION_CHECK: `https://verify.twilio.com/v2/Services/VA5681a04e24d8bc289d6008f39af37744/VerificationCheck`,
            TWILIO_AUTH_TOKEN: '97cd517dd6104fe0d1a9e6595510487f',
            TWILIO_ACCOUNT_SID: 'AC3cd8a9a45ccbb432105181d79f45b95d',
        },


        countCharacter(string) {
            let limit = (this.width < 1560) ? 25 : 45;
            if (string.length > limit) {
                string = string.substring(0, limit) + '...';
            }
            return string;
        },

        breadcrumbDynamic(arrayBreadcrumb) {
            let sendBreadcrumbDynamic = [];
            arrayBreadcrumb.forEach(element => {
                if (element != '') {
                    let info = {
                        'name': element.replaceAll('-', ' '),
                        'url': element,
                    };
                    sendBreadcrumbDynamic.push(info);
                }
            });
            return sendBreadcrumbDynamic;
        }
    }
;
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.

// The list of file replacements can be found in `angular.json`.


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

